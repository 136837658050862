//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//    3. Loading Custom Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

//@include googleFont((
//  "name": "Roboto",
//  "folder": "Roboto",
//  "files": "Roboto",
//  "weights": (300, 400, 700)
//));

/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/

//---------------------------------------------
// 3. Custom Fonts
//---------------------------------------------

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('../fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../fonts/ProximaNova-LightIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-LightIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('../fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova-RegularIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-RegularIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../fonts/ProximaNova-Light.woff2') format('woff2'),
  url('../fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baloo';
  src: url('../fonts/Baloo-Regular.woff2') format('woff2'),
  url('../fonts/Baloo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../fonts/ProximaNova-LightIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-LightIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../fonts/ProximaNova-SemiboldIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-SemiboldIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
