
/**
 * Heading
 */

h1 {
  font-family: 'Baloo';
  font-size: 32pt;
  color: rgb(172, 210, 52);
}

h2, h3, h4, h5, h6, h7 {
  font-family: 'Proxima Nova Rg';
  color: rgb(0, 172, 200);
  font-weight: bold;
}

h2 {
  font-size: 17pt;
}

h3 {
  font-size: 12pt;
}

/**
 * Text
 */

body {
  font-family: 'Proxima Nova Lt';
  font-size: 12pt;
}

.text-higlight {
  color: rgb(245, 130, 32);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bottom a {
  color: #fff;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
}

.bottom a:hover {
  color: #000;
}
